.app {
    font-family: "omyu_pretty", serif;
    text-align: center;
    display: flex;
    justify-content: center; /* 가로 중앙 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
    flex-direction: column;
    height: 100vh; /* 뷰포트 높이에 맞춤 */
    width: 100vw; /* 뷰포트 너비에 맞춤 */
    background: linear-gradient(to bottom, #ffffff, #90CAF9);
}

.router {
    height: 80%;
    text-align: center;
    display: flex;
    justify-content: center; /* 가로 중앙 정렬 */
    align-items: center; /* 세로 중앙 정렬 */
    flex-direction: column;
}

.router-content {
    width: 100%; /* 컨테이너의 너비를 최대로 설정 */
    max-height: 100%; /* 최대 높이를 .router의 높이에 맞춤 */
    overflow-y: auto; /* 세로 스크롤바 표시 */
}

.bottom-tabs {
    position: fixed; /* 고정 위치 */
    bottom: 0; /* 페이지 하단 */
    width: 100%; /* 전체 너비 */
    height: 4.5rem;
    background-color: white;
    border-top-left-radius: 10px; /* 왼쪽 상단 모서리 둥글게 */
    border-top-right-radius: 10px; /* 오른쪽 상단 모서리 둥글게 */
    border-top: 1px solid #e5e5e5; /* 테두리 */
}

.setting-item {
    display: flex;
    align-items: center;
    font-family: "SUITE-Regular", serif;
    font-size: 17px;
}

.top-bar {
    position: fixed; /* 고정 위치 */
    top: 0; /* 페이지 상단 */
    width: 100%; /* 전체 너비 */
    height: 10%;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.react-datepicker__input-container input {
    width: 100%;
    padding-right: 30px; /* 아이콘에 대한 공간 확보 */
    background: url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-solid-900.woff2') no-repeat right 10px center;
    background-size: 20px; /* 아이콘 크기 조절 */
    -webkit-font-smoothing: antialiased; /* 아이콘을 더 선명하게 */
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
